<script setup lang="ts">
const props = defineProps({
  slug: {
    type: String as PropType<string>,
    required: true,
  },
})

const { urlFor } = useSanityImage()
const ctaQuery = groq`*[_type == "callToAction" && slug.current == "${props.slug}"]{
  heading,
  subtitle,
  eyebrow,
  riskReducers,
  image{
    ...,
    "asset": {
      "_ref": asset._ref,
      "metadata": {
        "lqip": asset->metadata.lqip,
      }
    }
  }
}[0]`

const sanity = useSanity()
const { data: cta, error } = await useAsyncData(ctaQuery, () => sanity.fetch<Sanity.Default.Schema.CallToAction>(ctaQuery))
// // console.log(cta.value)

if (error.value) {
  // console.log('Error fetching CTA')
  console.error(error)
}
</script>

<template>
  <div v-if="cta">
    <UiFader v-if="cta.riskReducers" class="risk-reducers grid grid-cols-2 lg:grid-cols-4 gap-5 lg:gap-8 mb-12">
      <template
        v-for="riskReducer in cta.riskReducers"
        :key="riskReducer.title"
      >
        <CtaRiskReducer
          v-if="riskReducer && riskReducer.title && riskReducer.description"
          :risk-reducer="riskReducer"
        />
      </template>
    </UiFader>
    <UiFader
      v-if="cta.image && cta.image.asset"
      class="relative h-[400px] isolate w-full overflow-hidden bg-gray-300"
    >
      <nuxt-img
        :src="urlFor(cta.image.asset).width(1472).height(368).fit('crop').url()"
        :alt="cta.image.alt"
        quality="70"
        class="object-cover object-center w-full h-full block top-0 left-0"
        data-rellax-speed="-4"
        data-rellax-percentage="0.67"
        sizes="sm:576px md:704px lg:960px xl:1216vw xxl:1472px"
        :width="1472"
        :height="368"
        loading="lazy"
        :placeholder="cta.image.asset.metadata ? cta.image.asset.metadata?.lqip : urlFor(cta.image.asset).width(80).height(45).quality(10).fit('crop').url()"
      />
      <div class="absolute opacity-50 bg-black inset-0" />
      <div class="absolute inset-0 py-8 lg:py-12 px-4 2xl:px-20 h-[400px] flex items-center justify-center text-center">
        <div class="max-w-3xl p-8">
          <div v-if="cta.eyebrow" class="eyebrow light">
            {{ cta.eyebrow }}
          </div>
          <h1 v-if="cta.heading" class="text-xl lg:text-4xl font-bold mb-2 text-gray-50">
            {{ cta.heading }}
          </h1>
          <h2 v-if="cta.subtitle" class="text-sm lg:text-lg text-gray-100 font-normal mb-4">
            {{ cta.subtitle }}
          </h2>
          <div class="gap-x-6 mb-8">
            <UiBtn
              size="xl"
              color="forest"
              variant="solid"
              to="/property-services/sell"
              class="inline-block"
            >
              Request a Market Appraisal
            </UiBtn>
          </div>
        </div>
      </div>
    </UiFader>
  </div>
</template>

<style scoped>
</style>
